




import { Component, Prop, Vue } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";

@Component({
  components: {
    TheMask,
  },
})
export default class FscInputVat extends Vue {
  public name = "FscInputVat";

  @Prop()
  public value!: any;

  public vatTokens = {
    D: {
      pattern: /[dD]/,
      transform: (v: any) => v.toLocaleUpperCase(),
    },
    E: {
      pattern: /[eE]/,
      transform: (v: any) => v.toLocaleUpperCase(),
    },
    "#": { pattern: /\d/ },
  };

  private onInput(val: any): void {
    this.$emit("input", val);
  }
}
